/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var $document       = $(document);
  var $window         = $(window);
  var $windowWidth    = $window.width();
  var $windowHeight   = $window.height();
  var $html           = $('html');
  var $header         = $('.header');
  var $scrollTop      = 0;
  var $didScroll      = false;
  var $lastScrollTop  = $scrollTop;
  var $pauseScroll    = false;
  var $delta          = 5;
  var $vh             = document.body.clientHeight;
  var $ua             = navigator.userAgent;
  var $loadScreen     = $('.load-screen');
  var $lazyload       = new LazyLoad({ elements_selector:'.lazy' });
  var $locoHeight     = $('.locomotive').height();
  var $locomotive     = new LocomotiveScroll({
                          el: document.querySelector('[data-scroll-container]'),
                          smooth: true,
                          smartphone: {
                            smooth: false
                          },
                          tablet: {
                            smooth: true
                          }
                        });

  if($ua.indexOf('Safari') !== -1 && $ua.indexOf('Chrome') === -1) {
    $html.addClass('safari');
  }
  if($ua.indexOf('Chrome') !== -1) {
    $html.addClass('chrome');
  }
  if($ua.match(/iP(hone|od|ad)/i) ) {
    $html.addClass('browser-ios');
  }
  if($ua.indexOf('MSIE 10') !== -1) {
    $html.addClass('browser-ie ie10');
  }
  if(!!window.MSInputMethodContext && !!document.documentMode) {
    $html.addClass('browser-ie ie11');
  }
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test($ua)) {
    $html.addClass('browser-mobile');
  }

  // loader
  if($loadScreen) {
    var $loadScreenId = $loadScreen.data('id');

    if(typeof(Storage) !== 'undefined') {
      if(localStorage.getItem($loadScreenId)) {
        $loadScreen.remove();
        $loadScreen = false;
      } else {
        localStorage.setItem($loadScreenId, true);
        $loadScreen.addClass('ready');
      }
    } else {
      $loadScreen.remove();
      $loadScreen = false;
    }
  }

  function checkNotificationHeight() {
    var $notificationHeight = 0;

    if($('.notification-bar').length > 0) {
      $notificationHeight = $('.notification-bar').outerHeight();
    }

    $html.get(0).style.setProperty('--notification-height', $notificationHeight+'px');
  }
  checkNotificationHeight();

  $window.resize(function() {
    $windowWidth = $window.width();
    $windowHeight = $window.height();
    checkNotificationHeight();
  });

  if($locomotive) {
    $locomotive.on('scroll', function($el) {
      $scrollTop = $el.scroll.y;
      $didScroll = true;
    });
  } else {
    $window.scroll(function(e) {
      $scrollTop = $window.scrollTop();
      $didScroll = true;
    });
  }

  setInterval(function() {
    if($didScroll) {
      checkHeader();
      checkHeight();
      $didScroll = false;
    }
  }, 250);

  // mobile vh fix
  function adjustVH() {
    if($html.hasClass('browser-ios') || ($html.hasClass('browser-mobile') && $html.hasClass('chrome'))) {
      $vh = document.body.clientHeight;
      $html.get(0).style.setProperty('--vh', $vh+'px');
    }
  }
  function checkVH() {
    var $diff = Math.abs($vh - $windowHeight);

    if($diff > 100) {
      adjustVH();
    }
  }

  // hide/show header
  function checkHeader() {
    if(Math.abs($lastScrollTop - $scrollTop) <= $delta) {
      return;
    }

    if($scrollTop > $('.header-menu').height()) {
      $html.addClass('state--fixed-header');
    } else if($scrollTop <= 0) {
      $html.removeClass('state--fixed-header');
    }

    if($scrollTop > $lastScrollTop) {
      $html.removeClass('state--show-fixed-header');
    } else if(!$pauseScroll) {
      $html.addClass('state--show-fixed-header');
    }

    $lastScrollTop = $scrollTop;
  }
  checkHeader();

  // monitor height changes
  function checkHeight() {
    var $newLocoHeight = $('.locomotive').height();

    if($locoHeight !== $newLocoHeight) {
      updateLocomotive();
      $locoHeight = $newLocoHeight;
    }
  }

  // nav indicators
  function setPrimaryIndicator($activeItem, $resizing) {
    if($activeItem.length) {
      var $left = $activeItem.position().left;
      var $width = $activeItem.width();
      var $duration = ($resizing) ? 0 : 0.2;

      if($html.hasClass('state--nav-ready')) {
        gsap.to('.header-menu .nav-indicator', $duration, { left:$left, width:$width });
      } else {
        gsap.set('.header-menu .nav-indicator', { left:$left, width:$width, opacity:1 });
        gsap.to('.header-menu .nav-indicator', $duration, { y:0, onComplete:function() {
          $html.addClass('state--nav-ready');
        } });
      }
    }
  }
  function setNavIndicator($activeItem, $indicator, $resizing) {
    if($activeItem.length) {
      var $left = $activeItem.position().left;
      var $width = $activeItem.width();
      var $duration = ($resizing) ? 0 : 0.2;

      if($html.hasClass('state--nav-ready')) {
        gsap.to($indicator, $duration, { left:$left, width:$width });
      } else {
        gsap.set($indicator, { left:$left, width:$width, opacity:1 });
        gsap.to($indicator, $duration, { y:0, onComplete:function() {
          $html.addClass('state--nav-ready');
        } });
      }
    }
  }

  // update locomotive
  function updateLocomotive() {
    if($locomotive) {
      $locomotive.update();
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: function() {
        // view height
        adjustVH(); //chrome vh fix
        $window.resize(checkVH);

        // nav indicators
        var $activeItem = $('.menu-primary li.active');
        setNavIndicator($activeItem, '.header-menu .nav-indicator', false);

        $window.resize(function() {
          var $activeItem = $('.menu-primary li.active');
          setNavIndicator($activeItem, '.header-menu .nav-indicator', true);
        });

        $document.on('click', '.menu-primary a', function() {
          var $activeItem = $(this).parent();

          if(!$activeItem.hasClass('menu-item-has-children')) {
            if($activeItem.parent().hasClass('sub-menu')) {
              $activeItem = $activeItem.parent().parent();
            }
            setNavIndicator($activeItem, '.header-menu .nav-indicator', false);
          }
        });
        
        // sub menus
        $document.on('click', '.menu-item-has-children > a', function(e) {
          e.preventDefault();

          $(this).parent().toggleClass('hover');
        });
        $document.on('mouseup', function(e) {
          var $menuParent = $('.menu-item-has-children');

          if(!$menuParent.is(e.target) && $menuParent.has(e.target).length === 0) {
            $menuParent.removeClass('hover');
          }
        });

        $document.on('click', '.menu-item-has-children .back', function(e) {
          e.preventDefault();

          $(this).parent().parent().removeClass('hover');
        });

        // investors menu
        $document.on('click', '.btn--investors', function(e) {
          e.preventDefault();
          
          $html.toggleClass('state--investors-open');
        });
        $document.on('mouseup', function(e) {
          var $menuParent = $('.btn--investors, .investors-mega-menu');

          if(!$menuParent.is(e.target) && $menuParent.has(e.target).length === 0) {
            $html.removeClass('state--investors-open');
          }
        });
        
        $document.on('click', '.investors-mega-menu .back', function(e) {
          e.preventDefault();

          $html.removeClass('state--investors-open');
        });

        // mobile menu
        $document.on('click', '.btn--menu-toggle', function(e) {
          e.preventDefault();

          if($html.hasClass('state--menu-open')) {
            $html.removeClass('state--menu-open');
            $html.removeClass('state--investors-open');
            $('.menu-item-has-children.hover').removeClass('hover');
          } else {
            gsap.set('.mobile-menu', { scrollTo:0 });
            $html.addClass('state--menu-open');
          }
        });

        // team
        $document.on('click', '.team-member__button', function(e) {
          e.preventDefault();

          var $teamMember = $(this).parent();
          var $teamMemberBio = $teamMember.find('.team-member__bio');
          var $teamMemberBioHeight = $teamMember.find('.team-member__bio-wrapper').outerHeight();

          if($teamMember.hasClass('open')) {
            gsap.to($teamMemberBio, { duration:0.4, height:0, ease:'power2.out', onComplete:updateLocomotive });
            $teamMember.removeClass('open');
          } else {
            gsap.to($teamMemberBio, { duration:0.4, height:$teamMemberBioHeight, ease:'power2.out', onComplete:updateLocomotive });
            $teamMember.addClass('open');
          }
        });

        // board of directors
        $document.on('click', '.director__button', function(e) {
          e.preventDefault();

          var $director = $(this).parent();
          var $directorTarget = $director.data('director');
          var $directorBio = $director.find('.director__bio');
          var $directorBioHeight = $director.find('.director__bio-wrapper').outerHeight();

          if($director.hasClass('open')) {
            gsap.to($directorBio, { duration:0.4, height:0, ease:'power2.out', onComplete:updateLocomotive });
            $director.removeClass('open');
          } else {
            var $openDirector = $('.director.open');
            if($openDirector.length) {
              var $openDirectorContent = $openDirector.find('.director__bio');
              gsap.to($openDirectorContent, { duration:0.4, height:0, ease:'power2.out', onComplete:updateLocomotive });
              $openDirector.removeClass('open');
            }

            gsap.to($directorBio, { duration:0.4, height:$directorBioHeight, ease:'power2.out', onComplete:updateLocomotive });
            $director.addClass('open');

            $('.director-content.active').removeClass('active');
            $('#'+$directorTarget).addClass('active');
          }
        });
        if($('.director__button').length && $windowWidth > 900) {
          $('.director__button').first().trigger('click');
        }

        // testimonials
        if($('.testimonials-swiper').length) {
          new Swiper('.testimonials-swiper', {
            effect: 'fade',
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            }
          });
        }

        // investments
        if($('.investments').length) {
          function investmentProgress($scroller) {
            var $scrollerWidth = $scroller[0].scrollWidth;
            var $scrollLeft = $scroller.scrollLeft();

            var $investments = $scroller.closest('.investments');
            var $investmentsList = $investments.find('.investments__list');
            var $investmentsListOffset = $investmentsList.position().left - 20 + $scrollLeft;
            var $investmentItems = $investmentsList.find('li');
            var $investmentItemWidth = $investmentItems.first().outerWidth();
            var $totalInvestmentItems = $investmentItems.length;
            var $currentInvestmentItems = Math.floor(($windowWidth - $investmentsListOffset + $scrollLeft) / $investmentItemWidth);

            var $percentage = ($currentInvestmentItems/$totalInvestmentItems)*100;
            $investments.find('.progress').css({ width:$percentage+'%' });

            if($currentInvestmentItems > 0 && $currentInvestmentItems < 10) {
              $currentInvestmentItems = '0'+$currentInvestmentItems;
            }
            $investments.find('.current').html($currentInvestmentItems);
          }

          function setupInvestments() {
            $('.investments').each(function() {
              var $investments = $(this);

              var $investmentItems = $investments.find('.investment-item');
              var $totalInvestmentItems = $investmentItems.length;
              if($totalInvestmentItems > 0 && $totalInvestmentItems < 10) {
                $totalInvestmentItems = '0'+$totalInvestmentItems;
              }
              $investments.find('.total').html($totalInvestmentItems);

              var $scroller = $investments.find('.investments__scroller');
              var $scrollerWidth = $scroller[0].scrollWidth;

              if($scrollerWidth > $windowWidth) {
                $investments.css({ height:$windowHeight + ($scrollerWidth - $windowWidth) });
              } else {
                $investments.css({ height:$windowHeight });
              }
              updateLocomotive();

              investmentProgress($scroller);
            });
          }
          setupInvestments();
          $window.resize(setupInvestments);

          if($locomotive) {
            $locomotive.on('scroll', function() {
              $('.investments').each(function(index) {
                var $investments = $(this);
                var $scrollLeft = -$investments.offset().top;
                var $scroller = $investments.find('.investments__scroller');

                if($scrollLeft < 0) {
                  $scrollLeft = 0;
                }

                if($windowWidth > 900 && !$pauseScroll) {
                  $scroller.scrollLeft($scrollLeft);
                }
              });
            });
          } else {
            $window.scroll(function() {
              $('.investments').each(function(index) {
                var $investments = $(this);
                var $investmentsOffset = $investments.offset().top;
                var $scroller = $investments.find('.investments__scroller');
                var $scrollerWidth = $scroller[0].scrollWidth;
                var $scrollTotal = $scrollerWidth - $windowWidth;
                var $scrollLeft = $scrollTop - $investmentsOffset;

                if($scrollLeft < 0) {
                  $scrollLeft = 0;
                } else if($scrollLeft > $scrollTotal) {
                  $scrollLeft = $scrollTotal;
                }

                if($windowWidth > 900 && !$pauseScroll) {
                  $scroller.scrollLeft($scrollLeft);
                }
              });
            });
          }

          $('.investments__scroller').scroll(function() {
            investmentProgress($(this));
          });
        }

        // videos
        $document.on('click', '.video-wrapper .btn--play', function(e) {
          var $btn = $(this);
          var $url = $btn.attr('href');
          var $youtubeID = $url.split('v=');

          if($youtubeID.length > 0) {
            $youtubeID = $youtubeID[1];

            var $ampersandPosition = $youtubeID.indexOf('&');

            if($ampersandPosition != -1) {
              $youtubeID = $youtubeID.substring(0, $ampersandPosition);
            }
          } else {
            $youtubeID = false;
          }

          if($youtubeID) {
            e.preventDefault();

            var $videoWrapper = $btn.parent();
            $videoWrapper.html('<iframe src="https://www.youtube.com/embed/'+$youtubeID+'?autoplay=1" frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>');
          }
        });

        // accordion
        $document.on('click', '.accordion__title', function(e) {
          e.preventDefault();

          var $accordion = $(this).parent();
          var $accordionContent = $accordion.find('.accordion__content');
          var $accordionContentHeight = $accordion.find('.accordion__content-wrapper').outerHeight();

          if($accordion.hasClass('open')) {
            gsap.to($accordionContent, { duration:0.4, height:0, ease:'power2.out', onComplete:updateLocomotive });
            $accordion.removeClass('open');
          } else {
            gsap.to($accordionContent, { duration:0.4, height:$accordionContentHeight, ease:'power2.out', onComplete:updateLocomotive });
            $accordion.addClass('open');
          }
        });

        // anchor links
        $('a[href^="#"]').click(function(e) {
          var $link = $(this);
          var $target = $link.attr('href');
          var $targetEl = $($target);

          if($targetEl.length && !$targetEl.hasClass('resource-section') && !$targetEl.hasClass('reporting-year') && !$targetEl.hasClass('faq-section')) {
            e.preventDefault();

            $pauseScroll = true;

            var $y = $targetEl.offset().top;
            var $duration = 1;

            if($link.parent().hasClass('investments__footer')) {
              if($target === '#active-investments') {

                if($locomotive) {
                  $targetEl = $('#exited-investments');

                  $locomotive.scrollTo($targetEl[0], {
                    duration:0,
                    disableLerp:true,
                    callback:function() {
                      $locomotive.scrollTo($targetEl[0], {
                        offset:-$windowHeight,
                        disableLerp:true,
                        callback: function() {
                          setTimeout(function() {
                            $pauseScroll = false;
                          }, 250);
                        }
                      });
                    }
                  });
                } else {
                  var $exitedInvestmentsOffset = $('#exited-investments').offset().top;

                  gsap.to(window, { duration:0, scrollTo:$exitedInvestmentsOffset });
                  $y = $exitedInvestmentsOffset - $windowHeight;

                  gsap.to(window, { duration:$duration, scrollTo:$y, ease:'power2', onComplete:function() {
                    setTimeout(function() {
                      $pauseScroll = false;
                    }, 250);
                  } });
                }

                var $scroller = $('#active-investments .investments__scroller');
                $scroller.scrollLeft($scroller[0].scrollWidth);
              } else {

                if($locomotive) {
                  $locomotive.scrollTo($targetEl[0], {
                    offset:-$windowHeight,
                    duration:0,
                    disableLerp:true,
                    callback: function() {
                      $locomotive.scrollTo($targetEl[0], {
                        disableLerp:true,
                        callback: function() {
                          setTimeout(function() {
                            $pauseScroll = false;
                          }, 250);
                        }
                      });
                    }
                  });
                } else {
                  var $activeInvestmentsOffset = $('#active-investments').offset().top + $('#active-investments').outerHeight() - $windowHeight;

                  gsap.to(window, { duration:0, scrollTo:$activeInvestmentsOffset });
                  $y = $activeInvestmentsOffset + $windowHeight;

                  gsap.to(window, { duration:$duration, scrollTo:$y, ease:'power2', onComplete:function() {
                    setTimeout(function() {
                      $pauseScroll = false;
                    }, 250);
                  } });
                }

                var $scroller = $('#exited-investments .investments__scroller');
                $scroller.scrollLeft(0);
              }
            } else {
              if($locomotive) {
                $locomotive.scrollTo($targetEl[0], {
                  callback: function() {
                    setTimeout(function() {
                      $pauseScroll = false;
                    }, 250);
                  }
                });
              } else {
                gsap.to(window, { duration:$duration, scrollTo:$y, ease:'power2', onComplete:function() {
                  setTimeout(function() {
                    $pauseScroll = false;
                  }, 250);
                } });
              }
            }
          }
        });

        // blog filter select
        function setIcon() {
          var $select = $('.blog-filter__select');
          var $option = $select.find('option:selected');
          var $style = $option.data('style');

          $select[0].style = $style;
        }
        if($('.blog-filter__select').length) {
          setIcon();

          $('.blog-filter__select').change(function() {
            setIcon();
            window.location = $(this)[0].value;
          });
        }
        
        // form states
        $document.on('click', '.gform_next_button, .gform_previous_button', function() {
          $(this).addClass('loading');
        });
        $document.on('submit', '.gform_wrapper form', function() {
          $(this).find('button[type="submit"]').addClass('loading');
        });
        $document.on('gform_post_render', function(event, form_id, current_page) {
          $('gform_'+form_id).find('button[type="submit"]').removeClass('loading');
        });
      }
    },
    'page_template_home': {
      init: function() {
        var $heroCopy = new SplitText('.home-hero__copy h3', { type:'lines' });
        $window.resize(function() {
          $heroCopy.split();
        })

        if($loadScreen) {
          var $loadedImages = 0;
          $document.imagesLoaded().always(function(instance) {
            gsap.to('.load-screen', 0.4, { delay:0.3, x:'100%', force3D:true, ease:Power3.easeIn, onComplete:function() {
                gsap.delayedCall(0, function() {
                  $('.load-screen').remove();
                });
                $heroCopy.split();
                gsap.to('.hero-lines', { y:'0%', duration:0.5, ease:'power2', onComplete:startHomeHero });
              }
            });
          }).progress(function(instance, image) {
            $loadedImages++;
            var $y = -100*($loadedImages/instance.images.length)+'%';
            gsap.to('#loader', 0.3, { y:$y });
          });
        } else {
          setTimeout(function() {
            $heroCopy.split();
            gsap.to('.hero-lines', { y:'0%', duration:0.5, ease:'power2', onComplete:startHomeHero });
          }, 500);
        }

        // hero
        function startHomeHero() {
          var tl = gsap.timeline({ repeat:-1 });
          tl.to('.hero-lines', { y:'-33.33333%', duration:0.5, delay:2, ease:'power2' });
          tl.to('.hero-lines', { y:'-66.66667%', duration:0.5, delay:2, ease:'power2' });
        }
      }
    },
    'page_template_about': {
      init: function() {
        var $timiaIcon = $('.timia-icon');
        var $timiaIconWrapper = $('.about-hero__icon');
        var $timiaOffsetTop = $timiaIconWrapper.offset().top;
        var $timiaBaseWidth = $timiaIconWrapper.outerHeight();
        var $timiaMaxWidth = $timiaIconWrapper.outerWidth() * 2 + 100;
        if($windowWidth > $timiaMaxWidth) {
          $timiaMaxWidth = $windowWidth;
        }
        var $timiaMinScale = 1;
        var $timiaMaxScale = ($timiaMaxWidth/$timiaBaseWidth);

        $window.resize(function() {
          $timiaOffsetTop = $timiaIconWrapper.offset().top;
          $timiaBaseWidth = $timiaIconWrapper.outerHeight();
          $timiaMaxWidth = $timiaIconWrapper.outerWidth() * 2 + 100;
          if($windowWidth > $timiaMaxWidth) {
            $timiaMaxWidth = $windowWidth;
          }
          $timiaMinScale = 1;
          $timiaMaxScale = ($timiaMaxWidth/$timiaBaseWidth);
        });

        if($locomotive) {
          $locomotive.on('scroll', function($el) {
            var $percentage = ($scrollTop/($windowHeight+$timiaBaseWidth));
            var $scale = $timiaMinScale + ($percentage * ($timiaMaxScale - $timiaMinScale));

            if($scale < $timiaMinScale) {
              $scale = $timiaMinScale;
            } else if($scale > $timiaMaxScale) {
              $scale = $timiaMaxScale;
            }

            var $y = 0;
            if($scrollTop > $timiaOffsetTop) {
              $y = $timiaOffsetTop - $scrollTop;
            }

            $timiaIcon.css({ transform:'translate(-50%, '+$y+'px) scale('+$scale+')' });

            if($percentage > 0.5) {
              $('.about-hero__copy').addClass('hide-copy');
            } else {
              $('.about-hero__copy').removeClass('hide-copy');
            }
          });
        } else {
          $window.scroll(function() {
            var $percentage = ($scrollTop/($windowHeight+$timiaBaseWidth));
            var $scale = $timiaMinScale + ($percentage * ($timiaMaxScale - $timiaMinScale));

            if($scale < $timiaMinScale) {
              $scale = $timiaMinScale;
            } else if($scale > $timiaMaxScale) {
              $scale = $timiaMaxScale;
            }

            var $y = 0;
            if($scrollTop > $timiaOffsetTop) {
              $y = $timiaOffsetTop - $scrollTop;
            }

            $timiaIcon.css({ transform:'translate(-50%, '+$y+'px) scale('+$scale+')' });

            if($percentage > 0.5) {
              $('.about-hero__copy').addClass('hide-copy');
            } else {
              $('.about-hero__copy').removeClass('hide-copy');
            }
          });
        }

        // hack
        var $overviewCopy = $('.about-overview .offset-content__copy').clone();
        $('.about-overview .ping-pong__copy').prepend($overviewCopy);
      }
    },
    'page_template_use_cases': {
      init: function() {
        if($locomotive) {
          $locomotive.on('scroll', function() {
            var $useCase = $('.use-case.is-inview').last();
            if($useCase.length) {
              var $useCaseOffset = $useCase.offset().top ;
              var $useCaseId = $useCase.attr('id');

              if($useCaseOffset < $windowHeight/2) {
                $('.use-cases__list a.active').removeClass('active');
                var $link = $('.use-cases__list a[href="#'+$useCaseId+'"]');
                $link.addClass('active');

                var $indicatorY = $link.position().top;
                gsap.to('.use-cases__list .indicator', 0.2, { y:$indicatorY });
              }
            }
          });
        } else {
          $window.scroll(function() {
            $('.use-case').each(function() {
              var $useCase = $(this);
              var $useCaseOffset = $useCase.offset().top - $windowHeight/2;
              var $useCaseId = $useCase.attr('id');

              if($scrollTop > $useCaseOffset) {
                $('.use-cases__list a.active').removeClass('active');
                $('.use-cases__list a[href="#'+$useCaseId+'"]').addClass('active');
              }
            });
          });
        }
      }
    },
    'page_template_how_it_works': {
      init: function() {
        // phases
        if($locomotive && !$locomotive.options.isMobile) {
          $locomotive.on('scroll', function() {
            var $phases = $('.phases__list');
            var $phasesHeight = $phases.height();
            var $phasesScrollWidth = $phases[0].scrollWidth;
            var $phasesWidth = $phases.width();
            var $distance = $phasesScrollWidth - $phasesWidth;
            var $maxOffset = $windowHeight - $phasesHeight;
            var $phasesOffset = $phases.offset().top;

            if($phasesHeight > $windowHeight) {
              $maxOffset = $phasesHeight;
              $phasesOffset = $phases.offset().top + ($windowHeight/2);
            }

            var $percentage = ($maxOffset-$phasesOffset) / $maxOffset;
            if($percentage < 0) {
              $percentage = 0
            } else if($percentage > 1) {
              $percentage = 1;
            }

            if($distance > 0) {
              $phases.css({ transform:'translateX('+(-$distance*$percentage)+'px)' });
            } else {
              $phases.css({ transform:'translateX(0px)' });
            }
          });
        } else {
          $window.scroll(function() {
            var $phases = $('.phases__list');
            var $phasesHeight = $phases.height();
            var $phasesScrollWidth = $phases[0].scrollWidth;
            var $phasesWidth = $phases.width();
            var $distance = $phasesScrollWidth - $phasesWidth;
            var $maxOffset = $windowHeight - $phasesHeight;
            var $phasesOffset = $phases.offset().top - $maxOffset;

            if($phasesHeight > $windowHeight) {
              $maxOffset = $phasesHeight;
              $phasesOffset = $phases.offset().top - ($windowHeight/2);
            }

            var $offset = $scrollTop - $phasesOffset;
            if($offset < 0) {
              $offset = 0;
            } else if($offset > $maxOffset) {
              $offset = $maxOffset;
            }

            var $percentage = $offset / $maxOffset;

            if($distance > 0) {
              $phases.css({ transform:'translateX('+(-$distance*$percentage)+'px)' });
            } else {
              $phases.css({ transform:'translateX(0px)' });
            }
          });
        }

        // timeline
        if(!$locomotive) {
          $window.scroll(function() {
            $('.timeline__tooltip').each(function() {
              var $tooltip = $(this);
              var $tooltipOffset = $tooltip.offset().top - ($windowHeight/4)*3;

              if($scrollTop > $tooltipOffset) {
                $tooltip.addClass('visible');
              } else {
                $tooltip.removeClass('visible');
              }
            });
          });
        }
      }
    },
    'investors': {
      init: function() {
        // nav indicators
        var $activeItem = $('.investors-menu li.active');
        setNavIndicator($activeItem, '.investors-menu .nav-indicator', false);

        $window.resize(function() {
          var $activeItem = $('.investors-menu li.active');
          setNavIndicator($activeItem, '.investors-menu .nav-indicator', true);
        });

        $document.on('click', '.investors-menu a', function() {
          var $activeItem = $(this).parent();
          setNavIndicator($activeItem, '.investors-menu .nav-indicator', false);
        });

        if($loadScreen) {
          var $loadedImages = 0;
          $document.imagesLoaded().always(function(instance) {
            gsap.to('.load-screen', 0.4, { delay:0.3, x:'100%', force3D:true, ease:Power3.easeIn, onComplete:function() {
                gsap.delayedCall(0, function() {
                  $('.load-screen').remove();
                });
              }
            });
          }).progress(function(instance, image) {
            $loadedImages++;
            var $y = -100*($loadedImages/instance.images.length)+'%';
            gsap.to('#loader', 0.3, { y:$y });
          });
        }

        function setInlineInvestorsMenu() {
          var $activeItem = $('.inline-investors li.active');

          if($activeItem.length > 0) {
            var $menuWrapper = $('.investors-menu');
            var $positionLeft = $activeItem.position().left;
            var $x = $positionLeft - 16;

            gsap.to($menuWrapper, { duration:1, scrollTo:{ x:$x }, ease:'power2' });
          }
        }
        setInlineInvestorsMenu();
      }
    },
    'page_template_investor_information': {
      init: function() {
        // stocks toggle
        $document.on('click', '.stocks-switch button', function(e) {
          e.preventDefault();

          var $stocksSwitch = $('.stocks-switch');
          $stocksSwitch.toggleClass('otcmkts');

          if($stocksSwitch.hasClass('otcmkts')) {
            $('#graph-otcmkts').show();
            $('#graph-tsxv').hide();
          } else {
            $('#graph-otcmkts').hide();
            $('#graph-tsxv').show();
          }
        });
      }
    },
    'page_template_investor_faqs': {
      init: function() {
        $('.investor-faqs__list a').click(function(e) {
          e.preventDefault();

          var $link = $(this);
          var $target = $link.attr('href');

          if(!$link.hasClass('active')) {
            $('.investor-faqs__list a.active').removeClass('active');
            $link.addClass('active');

            var $indicatorY = $link.position().top;
            gsap.to('.investor-faqs__list .indicator', 0.2, { y:$indicatorY });

            if($target === '#all') {
              $('.faq-section').show();
            } else {
              $('.faq-section').hide();
              $($target).show();
            }

            if($locomotive) {
              $pauseScroll = true;
              $locomotive.scrollTo($target, {
                offset: -64,
                callback: function() {
                  setTimeout(function() {
                    $pauseScroll = false;
                  }, 250);
                }
              });
            }

            updateLocomotive();
          }
        });

        $('.investor-faqs__select').change(function() {
          var $target = $(this).val();
          
          if($target === '#all') {
            $('.faq-section').show();
          } else {
            $('.faq-section').hide();
            $($target).show();
          }

          updateLocomotive();
        });

        /*$window.scroll(function() {
          $('.faq-section').each(function() {
            var $faqSection = $(this);
            var $faqSectionOffset = $faqSection.offset().top - $('.investors-menu').outerHeight();
            var $faqSectionId = $faqSection.attr('id');

            if($scrollTop >= $faqSectionOffset-1) {
              $('.investor-faqs__list a.active').removeClass('active');
              $('.investor-faqs__list a[href="#'+$faqSectionId+'"]').addClass('active');
            }
          });
        });*/
      }
    },
    'page_template_investor_resources': {
      init: function() {
        // add resource count
        $('.investor-resources__list a').each(function() {
          var $link = $(this);
          var $target = $link.attr('href');
          var $count = $($target).find('.financial-report-item__quarter a, .resource-item').length;
          $link.find('.count').html($count);
        });

        // resource section toggle
        $document.on('click', '.investor-resources__list a', function(e) {
          e.preventDefault();

          var $link = $(this);
          var $target = $link.attr('href');
          var $first = true;

          if(!$link.hasClass('active')) {
            if($('.investor-resources__list a.active').length) {
              $('.investor-resources__list a.active').removeClass('active');
              $first = false;
            }

            $link.addClass('active');

            var $indicatorY = $link.position().top;
            gsap.to('.investor-resources__list .indicator', 0.2, { y:$indicatorY });

            $('.resource-section.active').removeClass('active');
            $($target).addClass('active');

            if(!$first && $locomotive) {
              $pauseScroll = true;
              $locomotive.scrollTo($target, {
                offset: -64,
                callback: function() {
                  setTimeout(function() {
                    $pauseScroll = false;
                  }, 250);
                }
              });
            }

            updateLocomotive();
          }
        });

        // check for hash
        var $target = window.location.hash.replace('anchor-', '');
        var $targetLink = $('.investor-resources__list a[href="'+$target+'"]');
        if($targetLink.length) {
          $targetLink.trigger('click');
        } else {
          $('.investor-resources__list a').first().trigger('click');
        }

        $document.on('change', '.investor-resources__select', function(e) {
          var $target = $(this).val();
          $('.resource-section.active').removeClass('active');
          $($target).addClass('active');

          updateLocomotive();
        });

        // financial report toggle
        $document.on('click', '.reporting-years a', function(e) {
          e.preventDefault();

          var $link = $(this);
          var $target = $link.attr('href');

          if(!$link.hasClass('active')) {
            $('.reporting-years a.active').removeClass('active');
            $link.addClass('active');

            $('.reporting-year.active').removeClass('active');
            $($target).addClass('active');

            updateLocomotive();
          }
        });
        $('.reporting-years').each(function() {
          $(this).find('a').first().trigger('click');
        });
      }
    },
    'post_type_archive_press': {
      init: function() {
        // set nav indicator
        var $link = $('.press-filter__list a[aria-current="page"]');
        $link.addClass('active');
        var $indicatorY = $link.position().top;
        gsap.to('.press-filter__list .indicator', 0, { y:$indicatorY });

        $document.on('click', '.press-filter__list a', function(e) {
          var $link = $(this);

          if(!$link.hasClass('active')) {
            $('.press-filter__list a.active').removeClass('active');
            $link.addClass('active');

            var $indicatorY = $link.position().top;
            gsap.to('.press-filter__list .indicator', 0.2, { y:$indicatorY });
          }
        });
      }
    },
    'blog': {
      init: function() {
        // nav indicators
        var $activeItem = $('.blog-filter li.active');
        setNavIndicator($activeItem, '.blog-filter .nav-indicator', false);

        $window.resize(function() {
          var $activeItem = $('.blog-filter li.active');
          setNavIndicator($activeItem, '.blog-filter .nav-indicator', true);
        });

        $document.on('click', '.blog-filter a', function() {
          var $activeItem = $(this).parent();
          setNavIndicator($activeItem, '.blog-filter .nav-indicator', false);
        });
      }
    },
    'single_post': {
      init: function() {
        // add arrows to buttons - HACK
        $('.btn').each(function() {
          $(this).append('<span class="icon icon--right"><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.22 5.06l6.28 6H2.6v2h14.9l-6.28 5.99 1.38 1.45 8.85-8.44-8.85-8.44-1.38 1.44z" fill="#1C294A"/></svg></span>');
        });

        // hide/show top bar
        if($locomotive) {
          $locomotive.on('scroll', function() {
            var $offsetTop = $('.blog-header__image').offset().top;
            var $offsetBottom = $('.blog-content').outerHeight() - $('.author--bio').outerHeight() - $('.comments').outerHeight();

            if($offsetTop < 0) {
              $html.addClass('state--show-top-bar');
            } else {
              $html.removeClass('state--show-top-bar');
            }

            var $percentage = -$offsetTop / $offsetBottom  * 100;
            if($percentage < 0) {
              $percentage = 0;
            } else if($percentage > 100) {
              $percentage = 100;
            }

            if($percentage === 100) {
              $('.blog-top-bar__progress').addClass('complete');
            } else {
              $('.blog-top-bar__progress').removeClass('complete');
            }

            $('.blog-top-bar__progress').css({ width:$percentage+'%' });

            // back to top button
            var $contentTop = $('.blog-content').offset().top;
            if($contentTop < 0) {
              $('.btn--top').addClass('active');
            } else {
              $('.btn--top').removeClass('active');
            }
          });
        } else {
          $window.scroll(function() {
            var $offsetTop = $('.blog-header__image').offset().top;
            var $offsetBottom = $('.blog-content').offset().top + $('.blog-content').outerHeight() - $windowHeight;

            if($scrollTop > $offsetTop) {
              $html.addClass('state--show-top-bar');
            } else {
              $html.removeClass('state--show-top-bar');
            }

            var $percentage = ($scrollTop - $offsetTop) / ($offsetBottom - $offsetTop) * 100;
            if($percentage < 0) {
              $percentage = 0;
            } else if($percentage > 100) {
              $percentage = 100;
            }

            if($percentage === 100) {
              $('.blog-top-bar__progress').addClass('complete');
            } else {
              $('.blog-top-bar__progress').removeClass('complete');
            }

            $('.blog-top-bar__progress').css({ width:$percentage+'%' });

            // back to top button
            var $contentTop = $('.blog-content').offset().top;
            if($scrollTop > $contentTop) {
              $('.btn--top').addClass('active');
            } else {
              $('.btn--top').removeClass('active');
            }
          });
        }
      }
    },
    'page_template_saascore_application': {
      init: function() {
        $document.on('gform_page_loaded', function(event, form_id, current_page) {
          if(current_page > 1) {
            $('.page-header').hide();
          } else {
            $('.page-header').show();
          }

          $pauseScroll = true;

          if($locomotive) {
            updateLocomotive();

            $locomotive.scrollTo('#gform_wrapper_'+form_id, {
              duration: 0,
              disableLerp: true,
              callback: function() {
                setTimeout(function() {
                  $pauseScroll = false;
                }, 500);
              }
            });
          } else {
            var $y = $('#gform_wrapper_'+form_id).offset().top;
            gsap.to(window, { duration:0, scrollTo:$y, ease:'power2', onComplete:function() {
                $pauseScroll = false;
              }
            });
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.